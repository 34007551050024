@import '../stylesheets/styles';

label {
  background-color: $white;
}

.uikit-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  padding: 100px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);

  .container-header{
    position: absolute;
    top: 26px;
    left: 0;
    border-left: 2px solid $active-default;

    .container-header-text {
      margin-left: 30px;
      @extend %heading-1;
    }
  }
}

.uikit-container-max-width-unset {
  max-width: unset;
}

.compact .uikit-container {
  max-width: none;
}

@include mobile {
  label {
    background-color: $background-color;
  }
  .uikit-container {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    padding: 0;

    .container-header {
      position: initial;
      border-left: none;
      margin-bottom: 20px;

      .container-header-text {
        margin-left: 0;
      }
    }
  }
}