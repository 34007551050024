
.dashboard {
  display: flex;
  gap: 50px;

  .card-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

  .wallet-info {
    height: fit-content;
  }
}
