@import 'src/styles';

.accounts {
  .accounts-container {
    min-height: 650px;
    padding: 100px 33px 33px 33px;
    justify-content: start;

    .accounts-list {
      width: 100%;

      .account {
        word-break: break-word;
        min-width: 207px;
      }
    }
    .accounts-head {
      display: flex;
      justify-content: space-between;

      .head-accounts {
        text-align: left;
        min-width: 201px;
      }

      .head-actions, .head-reserved, .head-available {
        text-align: left;
      }
    }

    .account-info {
      height: fit-content;
    }

  }
}

.accounts-head > :nth-child(n) {
  flex: 1;
  text-align: center;
}

@include mobile {
  .accounts {
    .accounts-container {
      padding: 0;
    }
  }
  .accounts-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}