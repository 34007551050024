@import './src/styles';

.container-web {
  padding: 100px 0 100px 0;
}

.container-cards {
  padding: 100px 15px 30px 30px;
  align-items: flex-start;
  max-width: 1000px;
  border-radius: 8px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.cards-dashboard {
  .dashboard-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 50px;

    .card-account-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      gap: 20px;
      padding-right: 15px;

      .account-sales-department-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 280px;

        .account-sales-department-title {
          color: $dark-neutral;
          @extend %body;
          font-weight: 600;
        }

        .account-number-selector {
          width: 100%;
        }
      }

      .account-balance-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 280px;

        .account-balance-title {
          color: $dark-neutral;
          @extend %body;
          font-weight: 600;
        }

        .account-balance-data-wrapper {
          @extend %heading-1;

          .account-balance-currency {
            color: $neutral;
            margin-right: 8px;
          }

          .account-balance-value {
            color: $header;
            line-height: 60px;
          }
        }
      }

      .top-up-button {
        height: 60px;
        width: 100%;
        max-width: 125px;
        min-width: 85px;
      }
    }

    .get-new-card-button {
      position: absolute;
      top: 15px;
      right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 200px;
      gap: 16px;
    }

    .get-new-card-link {
      position: absolute;
      top: 28px;
      right: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 8px;
      @extend %heading-2-sb;
      font-weight: 600;

      svg {
        color: $active-default;
        transition: all 0.2s ease-in;
      }

      &:hover {
        svg {
          color: $active-hover;
        }
      }

      &:active {
        svg {
          color: $active-active;
        }
      }

      &:disabled {
        svg {
          color: $neutral;
          cursor: initial;
        }
      }
    }

    .cards-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      max-height: 475px;
      width: 100%;
      row-gap: 30px;
      column-gap: 50px;
      overflow: auto;

      .new-card-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 280px;
        height: 126px;
        padding: 0 20px;
        border-radius: 16px;
        border: none;
        background-color: $white;

        svg {
          color: $active-default;
        }

        .new-card-title {
          @extend %heading-2;
          font-weight: 600;
          color: $dark;
          margin: 16px 0 10px 0;
        }

        .new-card-description {
          @extend %annotation;
          color: $neutral;
        }
      }

      .new-card-wrapper:hover {
        border: 1px solid $active-hover;
      }

      .new-card-wrapper:active {
        border: 1px solid $active-default;
      }

      .not-first-card {
        border: 1px solid $light-neutral;
        background-color: inherit;
      }

      .card-dashboard-view-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        width: 280px;
        height: 80px;
        padding: 16px;
        gap: 16px;
        border-radius: 8px;
        background: $white;
        box-shadow: 0 4px 38px 0 rgba(143, 39, 199, 0.05);
        border: none;

        .new-card-block {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 16px;
          width: 100%;

          .new-card-title {
            color: $dark;
            @extend %heading-2;
            font-weight: 600;
          }
        }

        .card-image-block {
          width: 73px;
          height: 46px;
          border-radius: 4px;

          svg {
            width: 73px;
            height: 46px;
          }
        }

        .card-data-block {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          gap: 8px;
          color: $neutral-dark;

          .card-number-block {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            gap: 4px;

            .card-number-text {
              @extend %body;
              font-weight: 600;
            }
          }

          .card-owner-block {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            .card-owner-text {
              @extend %caption;
              font-weight: 400;
            }

            svg {
              color: $bats-hover;
            }
          }
        }
      }

      .creating-card {
        position: relative;
        color: $dark;

        .creating-block {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          color: $dark;
          gap: 4px;
          opacity: 0.8;

          .status-text {
            @extend %heading-2;
            color: $dark;
          }
        }
      }

      .creating-card > *:not(.creating-block),
      .creating-card > *:not(.creating-block) * {
        opacity: 0.8;
        color: $dark !important;
      }

      .virtual {
        .currency-amount-block {
          color: $white;
        }
        .type-number-block {
          color: $white;
        }
        .owner-block {
          color: $white;
          svg {
            color: $bats-hover;
          }
        }
      }

      .virtual:hover {
        border: 1px solid $active-hover;
      }

      .virtual:active {
        border: 1px solid $active-default;
      }

      .virtual:disabled {
        border: none;
      }

      .physical {}

      .one-time-usage {}
    }
  }
}

.cards-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 100px;
}

.virtual-card-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 380px;
  padding: 0 10px;

  .virtual-card-form-title {
    @extend %heading-1;
    color: $dark;
  }

  .virtual-card-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    margin-top: 50px;

    .virtual-card-form-description {
      @extend %heading-2;
      font-weight: 600;
      color: $header;
      text-align: center;
    }

    .virtual-card-form-wallet-select {
      width: 100%;
      margin-top: 43px;
    }

    .virtual-card-form-authorized-person-select {
      width: 100%;
      margin-top: 24px;
    }

    .virtual-card-form-password-input {
      width: 100%;
      margin-top: 43px;
    }

    .virtual-card-form-button {
      width: 185px;
      height: 60px;
      margin-top: 24px;
      @extend %heading-2;
      font-weight: 600;
      color: $white;
    }
  }
}

.card-example-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 48px;
  border-radius: 16px;
  border: none;

  svg {
    width: 318px;
    height: 200px;
  }

  .cardholder-name {
    position: absolute;
    bottom: 22px;
    left: 17px;
    max-width: 190px;
    @extend %body;
    font-weight: 600;
    color: $white;
  }

  .card-example-icon-blocked {
    opacity: 0.46;
  }

  .card-example-bottom-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .card-example-system {
      @extend %heading-1;
    }
  }

  .card-example-blocked {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 70px;
    left: 0;
    @extend %heading-1;
    color: $white;

    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.card-settings-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px 50px;
  gap: 100px;

  .card-settings-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 318px;

    .card-settings-card-example {
      margin-top: 0;
    }

    .card-settings-card-actions-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 30px;
      gap: 50px;

      .card-settings-card-button-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @extend %caption;
        color: $black;

        .card-settings-card-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 56px;
          margin-bottom: 4px;
          border-radius: 16px;
          border: none;
          background-color: $bg-2;
          color: $active-default;
          cursor: pointer;
        }
      }

      .card-settings-card-button-wrapper:hover {
        color: $bats-hover;
        button {
          background-color: $bats-hover;
          color: $white;
        }
      }

      .card-settings-card-button-wrapper:active {
        color: $active-default;
        button {
          background-color: $active-default;
          color: $white;
        }
      }

      .button-disabled {
        color: $neutral !important;
        button {
          cursor: unset !important;
          background-color: $background-color !important;
          color: $bg-light !important;
        }
      }
    }
  }

  .card-settings-bar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex: 1;

    .card-settings-bar-title {
      @extend %heading-1;
      color: $black;
    }

    .card-settings-bar-options-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      min-width: 250px;
      margin-top: 40px;
      gap: 32px;
      border: none;

      .card-settings-bar-option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0 10px 0;
        border: none;
        border-bottom: 1px solid $light;
        background-color: inherit;

        svg {
          color: $dark;
        }

        span {
          border: none;
          padding: 0;
          @extend %heading-2;
          font-weight: 600;
          color: $header;
        }
      }

      .option-disabled {
        svg {
          color: $neutral;
        }

        span {
          color: $light-neutral;
        }
      }
    }
  }
}

.card-details-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px 50px;
  gap: 100px;

  .card-details-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 360px;

    .card-details-card {
      margin-top: 0;
    }
  }

  .card-details-options-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    gap: 20px;

    .card-details-option-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 10px;
      border: none;
      border-bottom: 1px solid $light-neutral;

      .card-details-option-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;

        .card-details-option-label {
          @extend %body;
          color: $dark-neutral;
        }

        .card-details-option-value {
          @extend %heading-2;
          font-weight: 600;
          color: $header;
        }
      }

      .card-details-option-copy-button {
        svg {
          color: $active-default;
          margin: 0;
        }
      }

      .card-details-option-copy-button:hover {
        svg {
          color: $active-hover;
        }
      }

      .card-details-option-copy-button:active {
        svg {
          color: $active-active;
        }
      }
    }
  }
}

@include mobile {
  .transactions-container {
    width: 280px;
  }

  .container-cards {
    align-items: center;
    max-width: unset;
    width: 100%;
    margin-top: 40px;
    padding: 20px 20px 20px 20px;
    background: $white;
  }

  .cards-dashboard {
    .dashboard-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;

      .card-account-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding-right: 0;

        .account-sales-department-wrapper {
          align-items: center;
          max-width: unset;
          margin-top: 10px;
        }

        .account-balance-wrapper {
          align-items: center;
          max-width: unset;
        }

        .top-up-button {
          max-width: unset;
          min-width: unset;
        }
      }

      .get-new-card-link {
        position: initial;
      }

      .wallets-wrapper {
        flex-direction: column;
        justify-content: center;
        gap: 20px;
      }

      .get-new-card-button {
        position: unset;
        top: unset;
        right: unset;
        width: 280px;
        margin-top: 20px;
      }

      .cards-wrapper {
        justify-content: center;
        align-items: center;
        max-height: 400px;
        gap: 10px;
        margin-top: 20px;

        .new-card-wrapper {
          width: 280px;
          height: 126px;
        }

        .card-wrapper {
          width: 280px;
          height: 126px;
        }

        .virtual-card-form-wrapper {
          padding: 0;
          margin-top: 10px;

          .virtual-card-form-title {
            @include heading-2();
            font-weight: 600;
            color: $dark;
          }
        }

        .card-example-wrapper {
          margin-top: 30px;
        }

        .card-dashboard-view-wrapper {
          .new-card-block {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;
            width: 100%;
          }
        }
      }
    }
  }

  .card-example-wrapper {
    margin-top: 30px;

    svg {
      width: 280px;
      height: 176px;
    }

    .cardholder-name {
      max-width: 165px;
      @include caption();
      font-weight: 600;
      color: $white;
    }
  }

  .card-settings-wrapper {
    display: flex;
    flex-wrap: unset;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    gap: 30px;
    width: 100%;
    max-width: 360px;
    min-width: 280px;

    .card-settings-card-wrapper {
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 360px;
      min-width: 280px;

      .card-settings-card-example {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 280px;
        margin-top: 0;
      }

      .card-settings-card-actions-wrapper {
        margin-top: 20px;
      }
    }
  }

  .card-details-wrapper {
    display: flex;
    flex-wrap: unset;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    gap: 30px;
    width: 100%;
    max-width: 360px;
    min-width: 280px;

    .card-details-card-wrapper {
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 360px;
      min-width: 280px;

      .card-details-card {
        width: 100%;
        min-width: 280px;
        margin-top: 0;
      }
    }
  }
}