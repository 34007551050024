// Base colour palette
$black: #000000;
$white: #FFFFFF;
$neutral: #7F8DA0;
$light: #F2F4F8;
$light-neutral: #D2DAE4;
$dark-neutral: #475568;
$active-default: #00BDA1;
$active-hover: #44D6C0;
$active-active: #00867E;
$bats-hover: #1BA9A0;

$header: #040222;
$dark: #1E293B;
$hit: #34C759;
$error: #DB215F;

$bg-primary: #3C81F6;
$bg-soft-primary: rgba(60,129,246,0.5);
$bg-dark: #1E293B;
$bg-soft-dark-2: rgba(30,41,59,0.5);
$bg-secondary: #475568;
$bg-secondary-2: #64758B;
$bg-soft-secondary-2: rgba(100,117,139,0.5);
$bg-danger: #FF4961;
$bg-soft-danger: #FCDBDD;
$danger-text: #8F2E34;
$background-color: #FAFBFD;
$bg-2: #FCF8FF;
$bg-light: #D2DAE4;
$bg-border: #E2E8F0;
$bg-success: #DAF5E7;

$av-3: #16D6B9;
$av-7: #96E7DD;
$av-8: #E6F9F8;
