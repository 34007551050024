@import './src/styles';

.authorization-form {
  @extend %auth-container;

  &.login {
    .authorization-title {
      margin: 40px 0 10px 0;
    }

    .authorization-title + .form {
      margin-top: 30px;
    }

    .alert-message + .form {
      margin-top: 0;
    }
  }


  .registration-icon {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .alert-message {
    margin: 10px 0 30px 0;
    animation: 0.3s ease-in-out marginBottom25;
  }

  @keyframes marginBottom25 {
    from {   margin-bottom: 0}
    to {   margin-bottom: 25px }
  }

  .form {
    width: 100%;
  }

  .form-description {
    color: $bg-dark;
    text-align: center;
  }

  .form-inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 49px;
    margin-bottom: 30px;
  }

  .form-forgot-password {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;

  }

  .button {
    margin-bottom: 30px;
  }

  .resend-wrapper {
    color: $neutral;
    text-align: center;
    margin-bottom: 0.5rem;
    @extend %caption;
    span {
      @extend %caption;
    }
  }

  .back-button {
    margin-bottom: 30px;
  }

  .security-wrapper {
    margin-bottom: 10px;
  }

  .return-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .resend-info {
    color: $dark;
  }
}

.password-recovery-button {
  margin-top: 25px;
}

.copyright {
  text-align: center;
  .link-normal {
    margin-left: 5px;
  }
}

.authorization-title {
  margin: 0 0 10px 0;
  @extend %auth-title;

  .email & {
    margin: 40px 0 20px 0;
  }
}

.sms-description {
  margin-bottom: 30px;
  font-size: $font-size-smaller;
  color: $neutral;
  text-align: center;
  max-width: 302px;

  strong {
    color: $dark;
    font-weight: normal;
  }
}

.change-email-wrapper {
  margin-bottom: 10px;
  font-size: 14px;
  max-width: 260px;
  color: $dark;
  text-align: center;
}

@include mobile {
  .authorization-form {
    width: 100vw;
    background-color: inherit;
    box-shadow: none;
  }

  .copyright {
    text-align: center;
  }
}
