@import '../stylesheets/styles';


.icon-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $dark-neutral;
  transition: all 0.2s ease-in;

  svg {
    color: $light-neutral;
    margin-right: 10px;
    transition: all 0.2s ease-in;
  }

  &:hover {
    color: $bats-hover;
    svg {
      color: $bats-hover;
    }
  }

  &:active, &.active {
    color: $active-default;
    svg {
      color: $active-default;
    }
  }

  &:disabled {
    color: $neutral;
    pointer-events: none;
    svg {
      color: $neutral;
    }
  }
}
