@import './src/styles';

.loader-wrapper {
  @extend %heading-2;
  color: $neutral;

  .loader-wrapper-icon {
    margin-left: 8px;
    animation: rotate 1.5s linear infinite;
  }

  &.application {
    width: 100%;
    text-align: center;
    background-color: $white;
    padding: 16px;
    border-radius: 6px;
  }
}

@keyframes rotate{
  to{ transform: rotate(360deg); }
}

.button-file {
  padding: 0 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: $bats-hover;
  color: $white;
  border: none;
  @extend %body;
  transition: all 0.2s ease-in-out;
}