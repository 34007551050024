@import '../../../styles';

.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background-color: $white;
  border-radius: 6px;
  width: fit-content;

  svg {
    min-width: 40px;
    margin-bottom: 16px;
  }

  .contact-us-header {
    @extend  %display-h1;
    margin: 0 0 16px 0;
    &.account {
      margin-bottom: 24px;
    }
  }

  .contact-us-description {
    @extend %body;
    margin-bottom: 24px;
    max-width: 440px;
  }

  .contact-us-link {
    text-decoration: none;
  }
}

@include mobile {
  .contact-us-container {
    flex-direction: column;
    align-items: flex-start;

    svg {
      margin: 0 0 20px 0;
    }

    .contact-us-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .contact-us-header {
      margin-bottom: 20px;
      text-align: left;
      width: 280px;
    }

    .contact-us-description {
      margin: 0 0 20px 0;
      text-align: left;
      width: 280px;
    }
  }
}