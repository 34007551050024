@import './src/styles';

.scheme-wrapper {
  padding: 40px;
  z-index: 2;
  .scheme-close-icon {
    @extend %close-icon;
  }

  .scheme-header {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .account {
      @extend %heading-2-sb;
      color: $header;
    }
  }

  .scheme-upload-documents-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 77px 16px 24px;
    border-radius: 16px;
    border: 1px solid $av-3;
    background: $background-color;

    .scheme-upload-documents-notice-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 15px;

      .scheme-upload-documents-notice-header {
        margin-bottom: 8px;
        @extend %heading-2;
        color: $header;
        font-weight: 600;
      }
      .scheme-upload-documents-notice-body {
        @extend %body;
        color: $header;
      }
    }

    .buttons-wrapper {
      gap: 24px;

      .upload-button {
        width: fit-content;
        padding: 0 20px;
      }

      &.upload-documents {
        margin-bottom: 40px;
      }

      &.transaction-commission {
        justify-content: flex-end;
      }
    }
  }

  .wrapper-balance {
    margin: 30px 0 20px 0;
    padding: 20px;
    border-bottom: 1px solid $light-neutral;

    .scheme-balance {
      @extend %heading-1;
      color: $header;
      margin-right: 5px;
    }

    .scheme-currency {
      @extend  %heading-1;
      color: $neutral;
    }
  }

  .scheme-line {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 38px;
    width: 100%;
    border-bottom: 1px solid $light-neutral;

    .left {
      @extend %caption;
      color: $header;
      width: 90px;
    }

    .center {
      @extend %body;
      color: $neutral;
      width: 70px;
      overflow-wrap: break-word;
      white-space: pre-line;
    }

    svg {
      min-width: 10px;
    }
  }

  .scheme-line:first-of-type {
    border-top: 1px solid $light-neutral;
  }

  .scheme-description {
    max-width: 366px;
    @extend %heading-2;
    color: $header;
    margin-bottom: 30px;
  }
}

.scheme-line-wrapper {
  margin-bottom: 20px;
}

.buttons-wrapper {
  margin-top: 20px;
  display: flex;
  gap: 50px;
}

.pop-up-success, .pop-up-logOut, .pop-up-error, .pop-up-google2fa {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 70px;
  max-width: 400px;

  .scheme-success-header {
    @extend %heading-1-sb;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 500;
  }

  .scheme-success-message, .scheme-error-message {
    @extend %caption;
    color: $neutral;
    margin-bottom: 30px;
    text-align: center;
    white-space: unset !important;
  }

  .scheme-error-message {
    max-width: 303px;
    text-align: center;
  }

  .scheme-success-button, .scheme-error-button {
    @extend %heading-2-sb;
    color: $white;
    background-color: $hit;
    height: 60px;
    min-width: 60px;
    border: none;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .scheme-error-button {
    background-color: $error;
  }

  .scheme-error-button:hover {
    background-color: $bg-danger;
  }
}

.pop-up-error {
  padding: 40px 45px;

  .scheme-success-button {
    width: 100px;
  }
}

.scheme-confirm-button {
  background: $error;
}

.scheme-confirm-button:hover {
  background: $bg-danger;
}

.pop-up-google2fa {
  max-width: 394px;
  padding: 40px 46px;

  .scheme-success-message {
    text-align: center;
    white-space: unset !important;
  }

  .google2fa_disable_icon {
    width: 96px;
    height: 95px;
  }
}

.pop-up-logOut {
  max-width: 394px;
  padding: 40px 46px;

  .logOut-description-wrapper {
    color: $neutral;
    margin-bottom: 20px;
    @extend %caption;
    text-align: center;
    .logOut-inactivity {
      color: $header;
    }
  }

  .scheme-success-message {
    padding: 0 40px;
    text-align: center;
    white-space: unset !important;
  }

  .google2fa_disable_icon {
    width: 96px;
    height: 95px;
  }

  .icon-error {
    color: $error;
  }

  .icon-hit {
    color: $hit;
  }
}

.account-details {
  padding: 40px 42px;

  .scheme-description {
    margin: 20px 0 0 0;
    max-width: 450px;
    color: $neutral;
    @extend %caption;
  }

  .scheme-iban {
    color: $neutral;
    margin-bottom: 8px;
  }

  .scheme-line {
    padding: 20px;
    gap: unset;
    margin-top: 0;
    .left {
      width: 160px;
    }

    .center {
      width: 230px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.transaction-info {
  width: 564px;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
  border-radius: 6px;
  .scheme-wrapper {
    width: 100%;
    .scheme-header {
      margin-bottom: 20px;
      p {
        @extend %heading-1-sb;
        font-weight: 500;
      }
    }
    .scheme-line {
      justify-content: flex-start;
      padding: 20px 0;
      margin: 0;
      .left {
        width: 220px;
        flex: 1;
      }
      .center {
        width: auto;
        flex: 1.5;
        word-break: break-word;
      }
    }

    .buttons-wrapper {
      justify-content: space-between;
      width: 100%;
      gap: 24px;

      &.upload-documents {
        margin-bottom: 40px;
      }

      &.upload-documents >div:first-child {
        width: 100%;
        max-width: 240px;

        .input {
          background: inherit;
          border: 1px solid $light-neutral;
        }

        .files-list-wrapper {
          .files-item {
            background: $light;
          }
        }
      }

      &.transaction-commission {
        justify-content: flex-end;
      }
    }

    .single {
      justify-content: center;
    }

    .sms-description {
      width: auto;
      margin-bottom: 20px;
    }
  }
}

.authorization-info {
  width: 400px;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
  border-radius: 6px;
  .scheme-wrapper {
    width: 100%;
    .scheme-header {
      margin-bottom: 20px;
      p {
        @extend %heading-1-sb;
        font-weight: 500;
      }
    }
    .scheme-line {
      justify-content: flex-start;
      padding: 20px 0;
      margin: 0;
      .left {
        width: 220px;
        flex: 1;
      }
      .center {
        width: auto;
        flex: 1.5;
        word-break: break-word;
      }
    }

    .buttons-wrapper {
      justify-content: space-between;
      width: 100%;
      gap: 24px;

      &.upload-documents {
        margin-bottom: 40px;
      }

      &.upload-documents >div:first-child {
        width: 100%;
        max-width: 240px;

        .input {
          background: inherit;
          border: 1px solid $light-neutral;
        }

        .files-list-wrapper {
          .files-item {
            background: $light;
          }
        }
      }
    }

    .single {
      justify-content: center;
    }

    .sms-description {
      width: auto;
      margin-bottom: 20px;
    }
  }
}

.google2fa {
  width: 382px;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
  border-radius: 8px;
  .scheme-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;

    .scheme-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 18px 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $bg-light;

      .scheme-header-text {
        @extend %heading-2;
        font-weight: 500;
      }

      .scheme-close-icon {
        color: $dark;
      }

      .scheme-close-icon:hover {
        color: $active-default;
      }
    }
    .scheme-instructions-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 10px;

      ol {
        padding-left: 1.5rem;
      }

      .scheme-instruction-text {
        @extend %body;
        font-weight: 400;
        color: $dark;
      }
    }

    .scheme-qr-code-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: fit-content;
      margin-bottom: 21px;

      .scheme-qr-code-image {
        width: 325px;
        height: 325px;
      }
    }

    .scheme-next-button {
      height: 60px;
      margin-top: 30px;
    }

    .sms-description {
      width: auto;
      margin-bottom: 20px;
    }
  }
}

.cardDetails {
  width: 366px;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
  border-radius: 8px;
  .scheme-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;

    .scheme-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 18px 10px;
      margin-bottom: 30px;
      border-bottom: 1px solid $bg-light;

      .scheme-header-text {
        @extend %heading-2;
        font-weight: 500;
      }

      .scheme-close-icon {
        color: $dark;
      }

      .scheme-close-icon:hover {
        color: $active-default;
      }
    }

    .scheme-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 235px;
      gap: 20px;

      .scheme-card-info-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid $bg-light;

        .scheme-card-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;

          .scheme-card-info-label {
            @extend %body;
            color: $dark-neutral;
          }

          .scheme-card-info-value {
            @extend %heading-2;
            font-weight: 600;
          }
        }

        .scheme-card-info-copy-button {
          svg {
            color: $active-default;
            margin: 0;
          }
        }

        .scheme-card-info-copy-button:hover {
          svg {
            color: $active-hover;
          }
        }

        .scheme-card-info-copy-button:active {
          svg {
            color: $active-active;
          }
        }
      }
    }

    .scheme-button {
      height: 60px;
      margin-top: 20px;
    }

    .sms-description {
      width: auto;
      margin-bottom: 20px;
    }
  }
}

@include mobile {
  .scheme-line {
    gap: 20px;
  }

  .pop-up-success {
    padding: 40px 50px;
  }

  .account-details {
    padding:  30px 20px 15px 20px;

    .scheme-line {
      flex-wrap: wrap;
    }
  }

  .scheme-wrapper {
    padding: 30px 20px;
    .wrapper-balance {
      margin-top: 17px;
      padding: 10px;
    }
  }

  .scheme-upload-documents-wrapper {
    padding: 16px !important;

    .scheme-upload-documents-notice-wrapper {
      margin-bottom: 4px !important;

      .scheme-upload-documents-notice-header {
        font-size: $font-size-normal !important;
        line-height: $line-height-smaller !important;
        font-weight: 400 !important;
      }

      .scheme-upload-documents-notice-body {
        font-size: $font-size-small !important;
        line-height: $line-height-small !important;
      }
    }

    .buttons-wrapper {
      flex-direction: column;
      gap: 50px !important;
      margin-bottom: 0 !important;

      >div:first-child {
        max-width: unset !important;
      }

      .upload-button {
        width: 100% !important;
      }
    }
  }

  .transaction-info {
    width: 100%;

    .scheme-wrapper {
      .scheme-line {
        font-size: 14px;
        line-height: 19px;
        padding: 7.5px 0;
        flex-direction: column;
        gap: 10px;

        .left, .center {
          width: 100%;
          font-size: 14px;
          padding-left: 10px;
        }
      }
      .sms-description {
        margin-bottom: 15px;
      }
    }

    .transfer-confirmation {
      .buttons-wrapper {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
